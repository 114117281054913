import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
const DISPLAY_RANGE = 2; // Number of page numbers to display on each side of the current page
/**
 * Pagination component for navigating through a list of items.
 *
 * This component provides a user-friendly way to navigate through a paginated list
 * of items, allowing users to change the current page and view different parts of the list.
 *
 * @component
 * @category UI Components
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentPage - The current active page.
 * @param {Function} props.onPageChange - A callback function to handle page changes.
 * @param {number} props.totalPages - The total number of pages.
 *
 * @returns {JSX.Element} The rendered Pagination component.
 *
 * @example
 * // Basic usage:
 * <Pagination
 *   currentPage={3}
 *   onPageChange={(page) => console.log(`Switched to page ${page}`)}
 *   totalPages={10}
 * />
 */
const Pagination = ({ currentPage, onPageChange, totalPages, disabled = false, }) => {
    const pageNumbers = [];
    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - DISPLAY_RANGE);
    let endPage = Math.min(totalPages, currentPage + DISPLAY_RANGE);
    // Ensure there are always 6 numbers displayed
    while (endPage - startPage < 5) {
        if (endPage < totalPages) {
            endPage++;
        }
        else if (startPage > 1) {
            startPage--;
        }
        else {
            break;
        }
    }
    // Generate the page numbers to display
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    if (totalPages <= 1) {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: `pagination ${disabled ? 'pagination--disabled' : ''}` }, { children: [_jsx("span", Object.assign({ className: "pagination__label" }, { children: "Page" })), _jsxs("ul", Object.assign({ className: "pagination__page-list" }, { children: [currentPage !== 1 && (_jsxs(_Fragment, { children: [_jsx("li", Object.assign({ className: "pagination__page pagination__page--move" }, { children: _jsx("button", Object.assign({ type: "button", "aria-disabled": disabled, className: "pagination__page-link pagination__page--nobutton", onClick: () => !disabled && onPageChange(1) }, { children: "<<" })) })), _jsx("li", Object.assign({ className: "pagination__page pagination__page--move" }, { children: _jsx("button", Object.assign({ type: "button", "aria-disabled": disabled, className: "pagination__page-link pagination__page--nobutton", onClick: () => !disabled && onPageChange(currentPage - 1) }, { children: "<" })) }))] })), pageNumbers.map((page) => (_jsx("li", Object.assign({ className: page === currentPage
                                ? 'pagination__page pagination__page--active'
                                : 'pagination__page' }, { children: page === currentPage ? (_jsx(_Fragment, { children: page })) : (_jsx("button", Object.assign({ type: "button", "aria-disabled": disabled, className: "pagination__page-link pagination__page--nobutton", onClick: () => !disabled && onPageChange(page) }, { children: page }))) }), page))), currentPage !== totalPages && (_jsxs(_Fragment, { children: [_jsx("li", Object.assign({ className: "pagination__page pagination__page--move" }, { children: _jsx("button", Object.assign({ type: "button", "aria-disabled": disabled, className: "pagination__page-link pagination__page--nobutton", onClick: () => !disabled && onPageChange(currentPage + 1) }, { children: ">" })) })), _jsx("li", Object.assign({ className: "pagination__page pagination__page--move" }, { children: _jsx("button", Object.assign({ type: "button", "aria-disabled": disabled, className: "pagination__page-link pagination__page--nobutton", onClick: () => !disabled && onPageChange(totalPages) }, { children: ">>" })) }))] }))] }))] })) }));
};
export default Pagination;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox, Transition } from '@headlessui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useState, useRef, } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '~/hooks';
import { LoadingIcon } from '~/icons';
import { isEmpty } from '~/utils';
/**
 * Fetches search suggestions based on the provided content types and search query.
 * @param {number[] | string[]} contentTypes - An array of content type IDs to filter the search results.
 * @param {string} debouncedSearchQuery - The search query to fetch suggestions for.
 * @returns {Promise<any>} - A promise that resolves to the search suggestions data.
 * @throws {Error} - If an error occurs while fetching data.
 */
const fetchSearchSuggestions = async (contentTypes, debouncedSearchQuery) => {
    try {
        const res = await fetch(`/api/v1/mo/search?contentTypes=${contentTypes}&q=${debouncedSearchQuery !== null && debouncedSearchQuery !== void 0 ? debouncedSearchQuery : ''}&size=10`);
        if (!res.ok)
            throw new Error('An error occurred while fetching data.');
        return await res.json();
    }
    catch (error) {
        throw new Error(`Failed to fetch data: ${error}`);
    }
};
const Searchbar = ({ disabled = false, setSearchItem, contentTypes, }) => {
    var _a;
    // set the search params to the query string params when the search query changes
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState((_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '');
    // used for debouncing the search query
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const searchButtonRef = useRef(null);
    /**
     * Uses the `useQuery` hook from React Query to fetch search suggestions.
     *
     * @returns {Object} An object containing:
     * - `options`: The search suggestions. This is the data returned from the query.
     * - `isLoading`: A boolean indicating if the query is currently loading.
     * - `isFetching`: A boolean indicating if the query is currently fetching.
     *
     * @property {Array} queryKey - An array that uniquely identifies this query. It includes the string 'search', the debounced search query, and the content types.
     * @property {Function} queryFn - An async function that fetches the data for this query. It sends a GET request to the `/api/v1/mo/search` endpoint with `contentTypes`, `debouncedSearchQuery`, and a fixed size of 10 as query parameters. It then parses the response as JSON.
     * @property {Boolean} placeholderData - This is set to `keepPreviousData`, which determines whether to keep the previous data while new data is being fetched.
     */
    const { data: options, isLoading, isFetching, isPending, isPlaceholderData, } = useQuery({
        queryKey: ['search', debouncedSearchQuery, contentTypes],
        queryFn: () => fetchSearchSuggestions(contentTypes, debouncedSearchQuery),
        placeholderData: keepPreviousData,
    });
    useEffect(() => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('q', searchQuery !== null && searchQuery !== void 0 ? searchQuery : '');
            return params;
        });
        // Disabling because it wants the setSearchParams function to be a dependency and that could lead to endless rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);
    useEffect(() => {
        // used for removing tab index from combobox button that is automatically added by combobox.button component
        if (searchButtonRef &&
            searchButtonRef.current &&
            searchButtonRef.current.hasAttribute('tabindex')) {
            searchButtonRef.current.removeAttribute('tabindex');
        }
        return () => {
            if (searchButtonRef && searchButtonRef.current) {
                searchButtonRef.current = null;
            }
        };
    }, [searchButtonRef]);
    const _handleAPISearchQueryChange = (value) => {
        setDebouncedSearchQuery(value);
    };
    const handleAPISearchQueryChange = useDebounce(_handleAPISearchQueryChange, 300);
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setSearchItem({ id: undefined, name: searchQuery });
        }
    };
    return (_jsx("div", Object.assign({ className: `headlessui-autocomplete ${disabled || isLoading ? 'headlessui-autocomplete--disabled' : ''}` }, { children: _jsx(Combobox, Object.assign({ nullable: true, disabled: isLoading || disabled, onChange: (value) => value &&
                !isEmpty(value.linkUrl) &&
                window.location.assign(value.linkUrl) }, { children: _jsxs("div", Object.assign({ className: "headlessui-autocomplete-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "headlessui-autocomplete__actions" }, { children: [_jsx(Combobox.Input, { className: "headlessui-autocomplete__input-container__input", placeholder: "Search Market Opportunities", onChange: (value) => {
                                    setSearchQuery(value.target.value);
                                    handleAPISearchQueryChange(value.target.value);
                                }, value: searchQuery, autoComplete: "off", onKeyUp: (e) => handleKeyUp(e) }), (isLoading || isPending || isFetching) && (_jsx(LoadingIcon, { className: "headlessui-autocomplete__input-container--icon--loading" })), _jsx(Combobox.Button, Object.assign({ className: "headlessui-autocomplete__button", onClick: (e) => {
                                    e.preventDefault();
                                    setSearchItem({ id: undefined, name: searchQuery });
                                }, ref: searchButtonRef }, { children: "Search" }))] })), _jsx(Transition, Object.assign({ as: Fragment, leave: "headlessui-autocomplete__transition--leave", leaveFrom: "headlessui-autocomplete__transition--leave-from", leaveTo: "headlessui-autocomplete__transition--leave-to" }, { children: _jsxs(Combobox.Options, Object.assign({ className: "headlessui-autocomplete__options" }, { children: [searchQuery && (_jsxs(Combobox.Option, Object.assign({ value: { id: null, name: searchQuery }, className: "headlessui-autocomplete__option--hidden" }, { children: ["Create \"", searchQuery, "!"] }))), options && options.length === 0 && searchQuery !== '' ? (_jsx("div", Object.assign({ className: "headlessui-autocomplete__option headlessui-autocomplete__option--not-found" }, { children: _jsx("span", { children: "Nothing found" }) }))) : (options &&
                                    options.map((option) => (_jsx(Combobox.Option, Object.assign({ className: ({ active }) => `headlessui-autocomplete__option ${active ? 'headlessui-autocomplete__option--active' : ''}
                      ${isPlaceholderData
                                            ? 'headlessui-autocomplete__option--loading'
                                            : ''}
                      `, value: option }, { children: option.linkUrl ? (_jsx("a", Object.assign({ href: option.linkUrl }, { children: option.title }))) : (_jsx("span", { children: option.title })) }), `${option.id}-${option.title}`))))] })) }))] })) })) })));
};
export default Searchbar;

import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
/**
 * A utility component that injects a React component into a specific DOM element.
 *
 * @param props - The props for the DOMInjector component.
 */
const DOMInjector = (props) => {
    const { reactComponent, DOMElement, path } = props;
    const router = createBrowserRouter([
        {
            path: `${path}`,
            element: reactComponent,
        },
    ]);
    /**
     * Retrieves the target HTML element by its ID and renders the provided React component into it.
     *
     * @returns Returns null if the target HTML element is not found; otherwise, returns the rendered React component.
     */
    const renderReactComponent = () => {
        const HtmlElement = document.getElementById(DOMElement.id);
        if (!HtmlElement) {
            return null;
        }
        const reactRoot = createRoot(HtmlElement, props.DOMElement.rootOptions);
        reactRoot.render(_jsx(StrictMode, { children: _jsx(RouterProvider, { router: router }) }));
    };
    renderReactComponent();
};
export default DOMInjector;
